import styled from "styled-components";
import { theme } from "../../styles";
import Close from "../../images/Czech_flag.svg";

export const ModalStyledContainer = styled.div`
  width: 350px;
  height: 300px;

  .modal-image {
    margin-left: 17px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slick-slider {
  }
  
  .slick-arrow {
    display: none;
  }

  .ReactModal__Content {
    background: red;
    background-color: red;
  }

  .modal-image img {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .gatsby-image-wrapper {
    width: 353px !important;
    height: 239px !important;
  }

  .button-div {
    display: flex;
    justify-content: flex-end;
  }

  .button-modal {
    border: none;
    background: none;
    margin-right: 17px;
  }

  .button-modal img {
    width: 24px;
  }

  @media (min-width: ${theme.breakpoints.s}) {
    width: 900px;
    height: auto;

    .slick-prev {
      display: flex;
      position: absolute;
      margin: 0px;
      padding: 0px;
      left: 0%;
      width: 44px;
      height: 44px;
      border: 1px solid white;
      transform: translate(0, -50%);
      z-index: 1;

      &:hover {
        background-color: ${theme.colors.bg};
        transition: background-color 150ms linear;

        &:before {
          color: ${theme.colors.primary};
        }
      }
    }

    .slick-next {
      display: flex;
      position: absolute;
      margin: 0px;
      padding: 0px;
      right: 0%;
      transform: translate(0, -50%);
      width: 44px;
      height: 44px;
      border: 1px solid white;

      &:hover {
        background-color: ${theme.colors.bg};
        transition: background-color 150ms linear;

        &:before {
          color: ${theme.colors.primary};
        }
      }
    }

    .slick-next:before,
    .slick-prev:before {
      font-family: inherit;
      font-size: 24px;
      color: white;
      opacity: 1;
    }

    .modal-image {
      margin-top: 10px;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;

      padding-top: 14px;
      padding-bottom: 14px;

      margin-left: 0;
    }

    .modal-image img {
      width: 100% !important;
      height: 100% !important;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
    }

    .button-div {
      display: flex;
      justify-content: flex-end;
    }

    .button-modal {
      border: none;
      background: none;
    }

    .button-modal img {
      width: 24px;
      cursor: pointer;

      &:hover {
        background-color: ${theme.colors.primary};
        transition: background-color 150ms linear;
        color: ${theme.colors.primary};

        &:before {
          color: ${theme.colors.primary};
        }
      }
    }

    .gatsby-image-wrapper {
      width: 700px !important;
      height: auto !important;
    }

    .slick-arrow,
    .slick-prev {
    }
  }
`;
