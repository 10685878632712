import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { ModalStyledContainer } from "./Modal.css";
import Img from "gatsby-image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const ModalContainer = ({ data, children }) => {
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <ModalStyledContainer>
      {children}
      <Slider {...settings}>
        {data &&
          data.map((photo, index) => {
            return (
              <div key={index} className="modal-image">
                <Img fluid={photo.photo.localFile.childImageSharp.fluid} />
              </div>
            )
          })}
      </Slider>
    </ModalStyledContainer>
  );
};

Modal.propTypes = {};

export default ModalContainer;
