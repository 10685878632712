import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql, Link } from "gatsby";
import LocalizedLink from "../LocalizedLink";
import ModalContainer from "../Modal/Modal";
import Modal from "react-modal";
import NavBurger from "./NavBurger";
import NavBurgerEnglish from "./NavBurgerEnglish";
import { LocaleContext } from "../Layout";
import EscButton from "../../../static/logos/Close.svg";
import CzeckFlag from "./../../images/Czech_flag.svg";
import BritainFlag from "./../../images/800px-Flag_of_Great_Britain_(1707–1800) 1.svg";
import { Desktop } from "./../Media/Media"


if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]');
}

const Navbar = ({ className }) => {
  const data = useStaticQuery(graphql`
    {
      navbar: allPrismicHeader {
        nodes {
          lang
          data {
            links {
              produkty {
                raw {
                  text
                }
              }
            }
            logo {
              localFile {
                publicURL
                extension
              }
            }
          }
        }
      }
      prismicGalerie {
        data {
          galerie {
            photo {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const lang = React.useContext(LocaleContext);
  const i18n = lang.i18n[lang.locale];

  const navbarData = data.navbar.nodes
    .filter((node) => node.lang === i18n.locale)
    .map((item) => {
      return item.lang;
    });

  const parteneriQuery = navbarData[0];
  console.log(parteneriQuery);

  const [modalIsOpen, setModalOpen] = React.useState(false);
  function openModal() {
    setModalOpen(true);
  }

  function closeModal() {
    setModalOpen(false);
  }

  Modal.setAppElement("body");

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      // background: "rgba(250, 250, 250, 0.7)",
      borderRadius: "0px",
      height: "auto",
      padding: "20px",
      backgroundColor: "rgba(19, 60, 139, 0.01)",
      border: "0",
    },
    overlay: {
      background: "rgba(19, 60, 139, 0.8)",
    },
  };

  return (
    <>
      <div className={className}>
        <Desktop>
          <div className="locale-switcher">
            <Link hrefLang="en-us" to="/en">
              <img src={BritainFlag} alt="" />
            </Link>
            <Link hrefLang="cs-cz" to="/">
              <img src={CzeckFlag} alt="" />
            </Link>
          </div>
        </Desktop>
        <div className="navbar">
          <div className="navbar-mobile">
            {parteneriQuery === "cs-cz" ? (
              <NavBurger
                data={data.prismicGalerie.data.galerie}
                style={customStyles}
              ></NavBurger>
            ) : (
              <NavBurgerEnglish
                style={customStyles}
                data={data.prismicGalerie.data.galerie}
              ></NavBurgerEnglish>
            )}
          </div>
        </div>
        <div className="nav-bar-desktop-container">
          <div className="navbar-mobile-logo">
            {data.navbar.nodes[0] &&
            data.navbar.nodes[0].data.logo.localFile.publicURL ? (
              <img
                src={data.navbar.nodes[0].data.logo.localFile.publicURL}
                alt=""
              />
            ) : (
              ""
            )}
          </div>
          <div className="navbar-desktop">
            {parteneriQuery === "cs-cz" ? (
              <div className="navbar-desktop-links">
                <LocalizedLink to="/#Produkty">Produkty</LocalizedLink>
                <LocalizedLink to="/#o-nas">
                  {" "}
                  <span>O nás</span>
                </LocalizedLink>
                <LocalizedLink to="/#Kontakty">
                  {" "}
                  <span>Kontakty</span>
                </LocalizedLink>
                <LocalizedLink to="/#Partneři">
                  {" "}
                  <span>Partneři</span>
                </LocalizedLink>
                <LocalizedLink to="/#Galerie" onClick={openModal}>
                  <span>Galerie</span>
                </LocalizedLink>
                <Modal
                  isOpen={modalIsOpen}
                  onRequestClose={closeModal}
                  style={customStyles}
                >
                  <ModalContainer data={data.prismicGalerie.data.galerie}>
                    {/* <div className="button-div">
                      <button className="button-modal" onClick={closeModal}>
                        <img src={EscButton} alt="" />
                      </button>
                    </div> */}
                  </ModalContainer>
                </Modal>
              </div>
            ) : (
              <div className="navbar-desktop-links">
                <LocalizedLink to="/#Product">
                  {" "}
                  <span>PRODUCTS</span>
                </LocalizedLink>
                <LocalizedLink to="/#About">
                  {" "}
                  <span>ABOUT US</span>
                </LocalizedLink>
                <LocalizedLink to="/#Contact">
                  {" "}
                  <span>CONTACTS</span>
                </LocalizedLink>
                <LocalizedLink to="/#Partenaire">
                  {" "}
                  <span>PARTNERS</span>
                </LocalizedLink>
                <LocalizedLink to="/#Galerie" onClick={openModal}>
                  <span className="no-padding">GALLERY</span>
                </LocalizedLink>
                <Modal
                  isOpen={modalIsOpen}
                  onRequestClose={closeModal}
                  style={customStyles}
                >
                  <ModalContainer data={data.prismicGalerie.data.galerie}>
                    {/* <div className="button-div">
                      <button className="button-modal" onClick={closeModal}>
                        <img src={EscButton} alt="" />
                      </button>
                    </div> */}
                  </ModalContainer>
                </Modal>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default styled(Navbar)`
  height: 77px;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;

  .locale-switcher {
    position: absolute;
    top: 20px;
    right: 50px;
    a {
      margin: 5px;
    }
  }

  .customOverlay {
    background: rgba(36, 123, 160, 0.7);
  }

  .button-modal {
    color: red;
  }

  .nav-local-switcher-main {
    position: absolute;
    bottom: 5%;

    &:focus {
      outline: none;
    }
  }

  .nav-local-switcher-main a {
    &:hover {
      background: none !important;
      color: red;
    }
  }

  .navbar-desktop {
    display: none;

    .Overlay {
      background-color: red;
    }
  }

  .navbar-mobile-logo {
    padding-top: 27px;
    padding-left: 18px;
  }

  .navbar-mobile-logo img {
    width: 230px;
    // height: 13px;
  }
  .navbar-mobile {
    .bm-burger-button {
      position: absolute;
      left: calc(100% - 66px);
      top: 25px;
    }

    .bm-burger-button img {
      width: 40px !important;
      height: 24px !important;
    }

    .bm-item span:after {
      width: 10%;
      height: 5px;
      background-color: white;
    }

    /* Color/shape of burger icon bars */
    .bm-burger-bars {
      background: #133c8b;
    }

    /* Color/shape of burger icon bars on hover*/
    .bm-burger-bars-hover {
      background: #133c8b;
    }

    /* Position and sizing of clickable cross button */
    .bm-cross-button {
      height: 24px;
      width: 24px;
      right: 20px !important;
      top: 20px !important;
      border: 1px solid white;
      border-width: 1px;
      padding: 12px;
    }

    .bm-cross-button span {
      top: 1px !important;
      right: 6px !important;
    }

    /* Color/shape of close button cross */
    .bm-cross {
      background: white;
      width: 1px !important;
      height: 19px !important;
    }

    /*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
    .bm-menu-wrap {
      position: fixed;
      height: 100%;
    }

    /* General sidebar styles */
    .bm-menu {
      background: #133c8b;
      padding-left: 34px;
      padding-top: 50px;
    }

    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
      fill: #133c8b;
    }

    /* Wrapper for item list */
    .bm-item-list {
      color: white;
      width: 287px;
      height: 405px;
    }

    .bm-item-list a {
      color: white;
      font-weight: 300;
      font-size: 27px;
      line-height: 45px;
      padding-top: 15px;
      text-transform: uppercase;
    }

    .bm-item-list a {
      &:hover,
      &:active {
        outline: none;
        border-bottom: none;
        background: linear-gradient(white, white) bottom left
          /* left or right or else */ no-repeat;
        background-size: 50% 3px;
      }

      &:focus {
        outline: none;
      }
    }

    .bm-burger-bars {
      width: 45px;
      height: 11% !important;
    }

    /* Individual item */
    .bm-item {
      display: inline-block;
    }

    .language-switcher {
      position: absolute;
      bottom: 2px;
      color: white;
    }
  }

  @media (min-width: 1210px) {
    width: 100%;
    height: 165px;
    .nav-bar-desktop-container {
      display: flex;
      justify-content: space-between;
    }
    .navbar-mobile {
      display: none;
    }

    .navbar-desktop {
      display: inline;
    }

    .navbar-desktop {
      display: flex;
      justify-content: space-between;
    }
    .navbar-desktop-logo {
      display: none;
    }

    .navbar-desktop-links {
      text-transform: uppercase;
      padding-top: 73px;
      padding-right: 40px;
    }

    .navbar-desktop-links a {
      font-size: 20px;
      padding-left: 0px;
      margin-left: 20px;
    }
    .navbar-mobile-logo {
      padding-top: 68px;
      padding-left: 40px;
    }

    .navbar-mobile-logo img {
      width: 500px;
      height: auto;
    }
  }
`;
