import React from "react";
import { slide as Menu } from "react-burger-menu";
import LocalizedLink from "../LocalizedLink";
import ModalContainer from "../Modal/Modal";
import Modal from "react-modal";
import BurgerIcon from "../../images/HAMBURGER MENU.svg";
import { LocaleSwitcher } from "../Layout";
import { Link } from "gatsby";
import EscButton from "../../../static/logos/Close.svg";

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]');
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    background: "rgba(19, 60, 139, 0.8)",
  },
};

class NavBurgerEnglish extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      modalIsOpen: false,
    };
  }

  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  handleStateChangeModal(state) {
    this.setState({ modalIsOpen: state.isOpen });
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  // This can be used to close the menu, e.g. when a user clicks a menu item
  closeMenu() {
    this.setState({ menuOpen: false });
  }

  render() {
    return (
      <div>
        <Menu
          customBurgerIcon={<img src={BurgerIcon} />}
          width={"100%"}
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
        >
          <LocalizedLink to="/#Product" onClick={() => this.closeMenu()}>
            {" "}
            <span>PRODUCTS</span>
          </LocalizedLink>
          <LocalizedLink to="/#About" onClick={() => this.closeMenu()}>
            {" "}
            <span>ABOUT US</span>
          </LocalizedLink>
          <LocalizedLink to="/#Contact" onClick={() => this.closeMenu()}>
            {" "}
            <span>CONTACTS</span>
          </LocalizedLink>
          <LocalizedLink to="/#Partenaire" onClick={() => this.closeMenu()}>
            {" "}
            <span>PARTNERS</span>
          </LocalizedLink>
          <LocalizedLink to="/#Galerie" onClick={() => this.closeMenu()}>
            {" "}
            <span onClick={() => this.openModal()}>GALLERY</span>
            <Modal
              isOpen={this.state.modalIsOpen}
              onRequestClose={() => this.closeModal()}
              contentLabel="Example Modal"
              style={this.props.style}
            >
              <ModalContainer data={this.props.data}>
                {/* <div className="button-div">
                  <button
                    className="button-modal"
                    onClick={() => this.closeModal()}
                  >
                    <img src={EscButton} alt="" />
                  </button>
                </div> */}
              </ModalContainer>
            </Modal>
          </LocalizedLink>
          <div className="nav-local-switcher-main">
            <Link hrefLang="cs-cz" to="/" className="nav-local-switcher">
              CZ<span>/</span>
            </Link>
            <Link hrefLang="en-us" to="/en" className="nav-local-switcher">
              EN
            </Link>
          </div>
        </Menu>
      </div>
    );
  }
}

export default NavBurgerEnglish;
